import BaseService from "./base.service";

class NewsService {
    News(page = null, search = null) {
        let params = [];
        if(search) {
            params.push({'key':'q', 'value':search});
        }
        return BaseService.apiGetRequest('news', page, params);
    }
	get(id) {
        return BaseService.apiGetRequest('news/'+id);
    }
    delete(news) {
        return BaseService.apiPostRequest('news/delete/', news);
    }    
    add(news) {
        return BaseService.apiPostRequest('news/add', news);
    }
    update(id, news) {
        return BaseService.apiPostRequest('news/'+id, news);
    }
    search(page=null) {
        return BaseService.apiGetRequest('news/search', page);
    }
}
export default new NewsService();