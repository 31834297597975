<template>
  <el-row :gutter="20">
    <el-col
        :xs="12"
        :sm="12"
        :md="12"
        :lg="12"
        :xl="12"
    >
      <div class="heading">
        <h1>{{title}}</h1>
      </div>
    </el-col>
    <el-row :gutter="20">
      <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
      >
        <div v-if="showMessage">
          <el-alert
              title="{{message.message}}"
              type="{{message.messageType}}"
              effect="{{message.effect}}"
          />
        </div>
      </el-col>
    </el-row>
    <el-col
        :xs="12"
        :sm="12"
        :md="12"
        :lg="12"
        :xl="12"
    >
      <div class="topsearch">
        <el-input
            v-model="search"
            size="small"
            placeholder="Type to search by name"
            @keyup="performSearch"
        >
          <template #append>
            <el-button icon="el-icon-search" @click="doClickSearch()"></el-button>
          </template>
        </el-input>
        <el-button
            type="primary"
            @click="performAddNew()"
        >
          {{addLabel}}
        </el-button>
      </div>
    </el-col>
  </el-row>
  <el-skeleton style="width: 100%;height: 300px;" :loading="loading" animated :count="3">
    <template #template>
      <div style="width: 100%;margin-top: 40px;">
        <el-skeleton-item variant="text" style="width:2%;margin-left: 20px;" />
        <el-skeleton-item variant="text" style="width:60%;margin-left: 20px;" />
        <el-skeleton-item variant="text" style="width:10%;margin-left: 20px;" />
        <el-skeleton-item variant="text" style="width:5%;margin-left: 20px;" />
        <el-skeleton-item variant="text" style="width:5%;margin-left: 20px;" />
        <el-skeleton-item variant="text" style="width:5%;margin-left: 20px;" />
      </div>
    </template>
    <template #default>
      <el-table
          ref="multipleTable"
          :data="rowsData.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
          style="width: 100%;box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%)"
          :row-class-name="tableRowClassName"
          :header-cell-class-name="tableCellClassName"
          @selection-change="handleSelectionChange"
          :show-header="true"
      >
        <el-table-column
            type="selection"
            width="55"
            style="padding: 0;"
        />
        <el-table-column
            v-for="column in columns"
            :key="column.key"
            :prop="column.key"
            :label="column.label"
            :sortable="column.sortable"
            :width="column.width"
        >
          <template #default="scope">
            <div v-if="!column.is_image">
                <span v-html="scope.row[column.key]"></span>
            </div>
            <div v-if="column.is_image">
              <el-image
                  style="width: 60px; height: 60px; border-radius: 50%;text-align: center;margin-top:5px;"
                  :src="scope.row.image"
              >
                <template #error>
                  <div class="image-slot" style="margin-top: 39%;">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </template>
              </el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            width="200"
        >
          <template #header>
            ACTIONS
          </template>
          <template #default="scope">
            <el-button
            size="small"
            type="primary"
            @click="performEdit(scope.row.id)"><i class="fa fa-edit i-style-sidebar"></i>
        </el-button>
            <el-button
                size="small"
                type="danger"
                icon="Delete"
                @click="performDelete(scope.$index, scope.row)"
            />
          </template>
        </el-table-column>
      </el-table>
    </template>
  </el-skeleton>
  <div class="pagination">
    <el-pagination
        background
        layout="sizes, prev, pager, next"
        :page-size="pageSize"
        :page-sizes="pageSizes"
        :total="totalPages"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
import settings from "@/settings";

export default {
  name: "list-content",
  props: {
    columns: Array,
    rowsData: Array,
    type: {
      type: String, // striped | hover
      default: "striped"
    },
    title: {
      type: String,
      default: "List"
    },
    subTitle: {
      type: String,
      default: ""
    },
    addLabel: {
      type: String,
      default: 'Add New'
    },
    meta: {current_page: Number, from: Number, last_page: Number, links: Array, path: String, per_page: String, to: Number, total: Number},
    message: {message:String, messageType: String, effect: String},
    showMessage: {
      type: Boolean,
      default: false
    },
    newUri: String,
    editUri: String
  },
  data() {
    return {
      page: 1,
      pageSize: settings.pagination.size,
      pageSizes: [1,2,3,4,5,10,25,50,100],
      multipleSelection: [],
      totalPages: 0,
      search: '',
      loading: true,
    }
  },
  mounted() {

  },
  computed: {
  },
  watch: {
    meta: function () {
      this.totalPages = this.meta.total;
      this.loading = false
    }
  },
  emits: ["handlePageChange", "handleSearchData", "handleDeleteData", "handleSizeChange"],
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (row && (rowIndex % 2 === 1)) {
        return 'table-row success-row';
      }else {
        return 'table-row';
      }
    },
    tableCellClassName() {
      return 'tableHeading';
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleCurrentChange(val) {
      this.page = val;
      this.$emit("handlePageChange", val);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      settings.pagination.size = val;
      this.$emit("handleSizeChange", val);
    },
    doClickSearch() {
      if(this.search === '') {
        return;
      }
      this.$emit("handleSearchData", {'name':this.search});
    },
    performSearch(event) {
      if (event.key === "Enter" || (event.key === "Backspace" && this.search === '')) {
        this.$emit("handleSearchData", {'name':this.search});
      }
    },
    performAddNew() {
      this.$router.push(this.newUri);
    },
    performEdit(editid){
      this.$router.push(this.editUri+"/"+editid);
    },
    performDelete(indexNo = null, deleteRow = null) {
      let result = confirm("Are you sure to delete this?");
      if (indexNo >= 0 && result) {
        this.$emit("handleDeleteData", {'indexNo':indexNo, 'row': deleteRow});
      }
    }
    // tableRowClassName({row, rowIndex}) {
    //   if (row && (rowIndex % 2 === 1)) {
    //     return 'success-row';
    //   }
    // },
    // tableCellClassName() {
    //   return 'tableHeading';
    // },
    // handleSizeChange(val) {
    //   settings.pagination.size = val;
    //   this.getTableData();
    // },
    // handleCurrentChange(val) {
    //   this.page = val;
    //   this.getTableData(val);
    // },
    // formatter(row) {
    //   return row.address;
    // },
    // filterTag(value, row) {
    //   return row.tag === value;
    // },
    // filterHandler(value, row, column) {
    //   const property = column['property'];
    //   return row[property] === value;
    // },
    // handleSelectionChange(val) {
    //   this.multipleSelection = val;
    // },
    // toggleSelection(rows) {
    //   if (rows) {
    //     rows.forEach(row => {
    //       this.$refs.multipleTable.toggleRowSelection(row);
    //     });
    //   } else {
    //     this.$refs.multipleTable.clearSelection();
    //   }
    // },
  }
}
</script>

<style lang="scss">
.el-input--mini .el-input__inner {
  height: 40px;
}
</style>

<style lang="scss" scoped>
.topsearch .el-input {
  width: 500px;
}

.heading {
  text-align: left;
  width: 100%;
  line-height: 2;
}
div .topsearch {
  float: right;
  display: inline-flex;
  padding: 15px 0px;
}

div .topsearch .el-input__inner {
  padding-right: 10px;
  height: 40px;
  width: 500px;
}

div .topsearch .el-input {
  padding-right: 10px;
}
.pagination {
  padding: 12px 5px;
  float: right;
}
.el-table :deep(.tableHeading) {
  background: #bebbc936 !important;
  text-transform: uppercase;
  font-size: .857rem;
  font-weight: 600;
  letter-spacing: .5px;
  color: #6e6b7b;
  font-family: Montserrat,Helvetica,Arial,serif;
  line-height: 1.45;
  height: 40px;
  padding: 0;
}
.el-table__row {
  height: 50px;
}
.el-table ::v-deep(.success-row) {
  //background: #f0f9eb61;
}
.el-pagination :deep(button) {
  border-radius: 50% !important;
}
.el-pagination :deep(.el-pager .number) {
  border-radius: 50% !important;
}
.el-pagination :deep(.el-pager .active) {
  background-color: #f00 !important;
}
.el-table :deep(.table-row td) {
  padding: 0 !important;
}
</style>
